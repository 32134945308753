<template>
  <section>
    <b-row class="match-height">
      <div class="col-12">
        <div class="card">
          <div class="card-header border-bottom">
            <h4 class="card-title">
              Courses ({{ services.length }})
            </h4>
            <b-button              
              v-b-modal.modal-no-animation
              variant="primary"
            >
              Add Course
              <feather-icon
                class="mr-25"
                icon="PlusIcon"
                size="15"
              />
            </b-button>
          </div>
          <div class="card-datatable table-responsive">
            <table class="datatables-ajax table ">
              <thead>
                <tr>
                  <th>SN</th>
                  <th>Title</th>
                  <th>Category</th>
                  <th>Code</th>
                  <th>Type</th>
                  <th>Duration</th>
                  <th>Fee</th>
                  <th>Discount</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th>SN</th>
                  <th>Title</th>
                  <th>Category</th>
                  <th>Code</th>
                  <th>Type</th>
                  <th>Duration</th>
                  <th>Fee</th>
                  <th>Discount</th>
                  <th>Actions</th>
                </tr>
              </tfoot>
              <tbody>
                <tr
                  v-for="(service, index) in services"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ service.title }}
                  </td>
                  <td>{{ service.title }}</td>
                  <td>{{ service.code }}</td>
                  <td>{{ service.type }}</td>
                  <td>{{ service.duration }}</td>
                  <td>{{ service.fee_formatted }}</td>
                  <td>{{ service.discount }}</td>
                  <td>
                    <b-button-group>
                      <b-link
                        :to="{ name: 'course-edit', params: { id: service.id } }"
                        class="font-weight-bold d-block text-nowrap btn btn-sm btn-primary"
                      >
                      <feather-icon
                        class="mr-25"
                        icon="EditIcon"
                        size="15"
                      />
                      </b-link>
                      <b-button 
                        type="button" 
                        class="btn-danger btn-sm"
                      >
                      <feather-icon
                        class="mr-25"
                        icon="DeleteIcon"
                        size="15"
                      />
                      </b-button>
                    </b-button-group>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </b-row>
    <b-modal
      id="modal-no-animation"
      content-class="shadow"
      title="Add Course"
      centered
      size="lg"
      no-close-on-backdrop
      hide-footer
    >
      <b-form>
        <b-row>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Course Title"
            >
              <b-form-input
                type="text"
                name="title"
                aria-label="Course Title"
                v-model="details.title"
                placeholder="Course Title"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Course Fee"
            >
              <b-form-input
                type="number"
                name="fee"
                aria-label="Course Fee"
                v-model="details.fee"
                placeholder="Course Fee"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Course Duration"
            >
              <b-form-input
                type="text"
                name="duration"
                aria-label="Course Duration"
                v-model="details.duration"
                placeholder="Course Duration"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Discount(%)"
            >
              <b-form-input
                type="text"
                name="discount"
                aria-label="Discount"
                v-model="details.discount"
                placeholder="Percentage discount"
              />
            </b-form-group>
          </b-col>
          <b-col lg="12" md="12">
            <b-form-group
              class="text-primary"
              label="Description"
            >
              <b-form-textarea
                name="description"
                aria-label="Description"
                v-model="details.description"
                placeholder="Description"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Select Categories"
            >
              <b-form-checkbox-group
                name="categories"
                v-model="details.categories"
              >
                <b-form-checkbox 
                  v-for="category in categories" 
                  :key="category.id" 
                  :value="category.id"
                  class="mr-1 mb-1"
                >
                  {{category.name}}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6">
            <b-form-group
              class="text-primary"
              label="Course Cover"
            >
              <b-form-file
                @change="prepareFile"
                accept=".jpg, .png, .gif"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
            </b-form-group>
          </b-col>
          <b-col md="12" lg="12" class="text-center">
            <b-button variant="primary" size="lg" @click="addCourse">
              Submit 
              <b-spinner
                variant="white"
                small
                class="ml-1"
                v-show="loading"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>      
  </section>
</template>

<script>
import {
  BRow, BLink, BAvatar, BModal, BCard, BCardText, BButtonGroup,
  BButton, BForm, BFormRow, BFormGroup, BCol, BFormInput,
  BFormTextarea, BFormCheckboxGroup, BFormCheckbox, BFormFile,
  BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BButtonGroup,
    BSpinner,
    BFormTextarea,
    BFormFile,
    BFormInput,
    BFormCheckboxGroup,
    BFormCheckbox,
    BLink,
    BAvatar,
    BCard,
    BModal,
    BCardText,
    BButton,
    BForm,
    BFormRow,
    BFormGroup,
    BCol,
  },
  data() {
    return {
      data: {},
      services: [],
      details: {},
      categories: [],
      loading: false, 
      form: new FormData()   
    }
  },
  mounted() {
    this.getServices()
    this.getCategories()
  },
  methods: {
    getServices() {
      this.$http.get(`${this.$url}/courses`)
        .then(response => {
          this.services = response.data.data.data
        })
    },
    getCategories() {
      this.$http.get(`${this.$url}/category`)
        .then(response => {
          if(response.data.status) {
            this.categories = response.data.data.data
          }
        })
    },
    prepareFile(event) {
      const files = event.target.files || event.dataTransfer.files
      if (!files.length) return
      this.form.append('cover_photo', files[0])
    },    
    addCourse() {
      this.loading = true
      this.form.append("title", this.details.title)
      this.form.append("discount", this.details.discount)
      this.form.append("description", this.details.description)
      this.form.append("fee", this.details.fee)
      this.form.append("duration", this.details.duration)
      this.form.append("categories", this.details.categories)
      this.$http.post(`${this.$url}/courses/create`, this.form)
        .then(response => {
          console.log("categories", response.data)
          if(response.data.status) {
            this.services.push(response.data.data) 
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })            
          }
        })
        .catch(err => console.log(err))
        .finally(() => this.loading = false)
    },
  },
}
</script>
